// import { useTranslation } from "react-i18next";

export const LinksData = () => {
  // const { t } = useTranslation();

  return [
    {
      title: "Dizaynlar",
      link: "/#design",
    },
    // {
    //   title: "Narxlar",
    //   link: "/",
    // },
    // {
    //   title: t("links.login"),
    //   link: "/#upcomingmatches",
    // },
    // {
    //   title: t("links.ffwc2024"),
    //   link: "/#ffwc2024",
    // },
    
  ];
};
